import fm from 'format-message';
import PropTypes from 'prop-types';
import textStyles from 'config/branding/textStyles';
import Image from '@activebrands/core-web/components/Image';
import Link from '@activebrands/core-web/components/Link';
import SpinnerIcon from '@activebrands/core-web/components/icons/SpinnerIcon';
import { styled } from '@activebrands/core-web/libs/styletron';
import ArticleCardEnd from 'components/ArticleCardMini/ArticleCardEnd';
import StateIcon from 'components/icons/StateIcon';
import Tag from 'components/text/Tag';
import { useStyletron } from 'styletron-react';

export const Wrapper = styled('div', {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    background: 'var(--color-bg)',
    borderRadius: '8px',
    padding: '4px',
});

export const ImageAndInfoWrapper = styled('div', {
    display: 'flex',
    flex: 1,
});

export const ImageWrapper = styled(Link, {
    display: 'flex',
    position: 'relative',
    flexShrink: '0',
});

export const Column = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingLeft: '8px',
});

export const Info = styled('div', {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
});

export const Name = styled(Link, {
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    ...textStyles['Primary/16_100_-05'],
});

export const Text = styled('p', {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
});

export const Remove = styled('button', {
    textAlign: 'right',
    paddingBottom: '4px',
});

export const Loading = styled(SpinnerIcon, {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
});

const cardEnds = {
    default: ArticleCardEnd,
};

const ArticleCardMini = ({
    $style = {},
    containImage,
    imageSrc,
    name,
    onClick = () => null,
    publishedAt,
    tag,
    to,
    type = 'default',
}) => {
    const [css] = useStyletron();
    const CardEnd = cardEnds[type];

    return (
        <Wrapper $style={$style}>
            <ImageAndInfoWrapper>
                <ImageWrapper to={to} onClick={onClick}>
                    <Image
                        $style={{
                            height: '110px',
                            width: '88px',
                            objectFit: containImage ? 'contain' : 'cover',
                            background: 'var(--color-bg-image)',
                        }}
                        alt={name}
                        draggable={false}
                        loading="lazy"
                        placeholder={containImage && 'none'}
                        src={{ url: imageSrc, width: 96 }}
                    />
                </ImageWrapper>
                <Column $style={{ flexShrink: 1, minWidth: '0' }}>
                    <Info>
                        {tag && (
                            <Tag
                                $style={{
                                    marginBottom: '8px',
                                    color: 'var(--color-text-subtle)',
                                    textTransform: 'capitalize',
                                }}
                                fontKeys="Miscellaneous/12_100_500"
                            >
                                {tag}
                            </Tag>
                        )}
                        {name && (
                            <Name to={to} onClick={onClick}>
                                {name}
                            </Name>
                        )}
                    </Info>
                    {to && (
                        <div
                            className={css({
                                display: 'flex',
                                alignItems: 'center',
                                paddingBottom: '8px',
                                ...textStyles['Primary/14_140_-2'],
                            })}
                        >
                            <Link to={to}>
                                {fm('Read more')}
                                <StateIcon
                                    size="12px"
                                    state="plus"
                                    style={{ marginLeft: '8px', transform: 'translateY(1px)' }} // alignment
                                    type="plus"
                                />
                            </Link>
                        </div>
                    )}
                </Column>
            </ImageAndInfoWrapper>
            <CardEnd publishedAt={publishedAt} />
        </Wrapper>
    );
};

ArticleCardMini.propTypes = {
    $style: PropTypes.object,
    containImage: PropTypes.bool,
    imageSrc: PropTypes.string,
    name: PropTypes.string,
    onClick: PropTypes.func,
    publishedAt: PropTypes.string,
    tag: PropTypes.string,
    to: PropTypes.string,
    type: PropTypes.string,
};

export default ArticleCardMini;
